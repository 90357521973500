import React from 'react';

export enum StageState {
  COMPLETED = 'completed',
  CURRENT = 'current',
  NEXT = 'next',
}

interface StageProps {
  id: number;
  name: string;
  state: StageState;
  onClick: (id: number) => void;
}

const Stage: React.FC<StageProps> = ({ onClick, id, name, state }) => {
  const onStepClick = (stageId: number) => {
    onClick(stageId);
  };

  const stateToClassName = (state: StageState) => {
    switch (state) {
      case StageState.COMPLETED:
        return 'completed';
      case StageState.CURRENT:
        return 'current';
      case StageState.NEXT:
        return 'next';
      default:
        return '';
    }
  };

  return (
    <div className="step" onClick={() => onStepClick(id)}>
      <div className={`step-number ${stateToClassName(state)}`}>{id + 1}</div>
      <div>{name}</div>
    </div>
  );
};

export default Stage;
