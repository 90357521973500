import client from '../../app/axios';

export class PuzzleAdapter {
  static async fetchDefinition(id: number) {
    const definition = await client.get(`/puzzles/${id}/definition`, {
      responseType: 'json',
    });

    return definition.data;
  }

  static async fetchSheet(id: number) {
    const puzzlesheet = await client.get(`/puzzles/${id}/sheet`, {
      responseType: 'blob',
    });

    return puzzlesheet.data;
  }
}
