export default class PuzzleColliders {
  constructor(zoneSize, pieceSize) {
    this.create(zoneSize, pieceSize);
  }

  static create(zoneSide, pieceSize) {
    return new PuzzleColliders(zoneSide, pieceSize);
  }

  createCollider(offsetX, offsetY) {
    return {
      get(v) {
        return {
          x: v.x + offsetX,
          y: v.y + offsetY,
        };
      },
    };
  }

  create(zoneSize, pieceSize) {
    this.top = this.createCollider(pieceSize / 3 + zoneSize / 2, 0);
    this.right = this.createCollider(pieceSize, pieceSize / 3 + zoneSize / 2);
    this.bottom = this.createCollider(pieceSize / 3 + zoneSize / 2, pieceSize);
    this.left = this.createCollider(0, pieceSize / 3 + zoneSize / 2);
  }

  get(side, v) {
    const collider = this[side];

    return collider.get(v);
  }
}
