import React, { Fragment, useRef } from 'react';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';

interface ImageChooserProps {
  onImage: (file: File) => void;
}

const ImageChooser: React.FC<ImageChooserProps> = ({ onImage }) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (fileInput.current) {
      fileInput.current.files = null;
      fileInput.current.click();
    }
  };

  const onFiles = () => {
    const input = fileInput.current;

    if (!input) {
      return;
    }

    const files = input.files;

    if (!files) {
      return;
    }

    const file = files[0];

    onImage(file);
  };

  return (
    <Fragment>
      <input
        onChange={() => onFiles()}
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        ref={fileInput}
      />
      <div className="image-chooser" onClick={() => onClick()}>
        <div className="image-icon">
          <Icon icon={Icons.IMAGE} size={64}></Icon>
        </div>
        <span className="choose-text">Choose an Image</span>
      </div>
    </Fragment>
  );
};

export default ImageChooser;
