import { Puzzle, PuzzleGroup } from '../../libs/puzzle';
import Puzzlesheet from '../../libs/puzzlesheet';

export interface GroupState {
  x: number;
  y: number;
  gid: number;
}

export interface PieceState {
  x: number;
  y: number;
  id: number;
  gid: number;
}

export interface PuzzleState {
  groups: { [key: string]: GroupState };
  pieces: { [key: string]: PieceState };
}

export class PuzzleRebuilder {
  static rebuild(pieceData: any[], progressBlob: string) {
    return this.fromProgress(this.buildObjects(pieceData), progressBlob);
  }

  static buildObjects(puzzleData: any[]) {
    const pieces = [];

    for (let i = 0; i < puzzleData.length; i++) {
      pieces.push(Puzzle.create(puzzleData[i]));
    }

    return pieces;
  }

  static fromProgress(puzzles: Puzzle[], progress: string) {
    if (!progress) {
      return puzzles;
    }

    console.log('Rebuilding from progress');

    const { pieces, groups } = JSON.parse(progress) as PuzzleState;
    const groupDict: { [key: string]: PuzzleGroup } = {};

    if (Object.keys(pieces).length === 0) {
      return puzzles;
    }

    for (const [gid, group] of Object.entries(groups)) {
      groupDict[gid] = PuzzleGroup.create(gid, {
        x: group.x,
        y: group.y,
      });
    }

    for (let i = 0; i < puzzles.length; i++) {
      const puzzlePiece = puzzles[i];
      if (!pieces[i]) {
        continue;
      }

      const { x, y, gid } = pieces[i];

      puzzlePiece.setPosition({
        x,
        y,
      });

      const group = groupDict[gid];

      if (group) {
        group.add(puzzlePiece);
      }
    }

    return puzzles;
  }

  static async fromDefinition(puzzles: any[], puzzlesheet: Blob) {
    const size = puzzles[0].actualSize;

    const extractor = await Puzzlesheet.createExtractor({
      spritesheet: puzzlesheet,
      spriteSize: size,
    });

    const puzzleObjects: any[] = [];

    for (let i = 0; i < puzzles.length; i++) {
      const puzzleData = puzzles[i];

      const puzzleImage = await extractor.extractSprite(
        puzzleData.row,
        puzzleData.column
      );

      puzzleObjects.push({
        ...puzzleData,
        imgSrc: puzzleImage,
      });
    }

    return puzzleObjects;
  }
}
