import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '.';
import client from '../axios';

interface Puzzle {
  id: number;
  title: string;
  numOfRows: number;
  numOfColumns: number;
  collaborationKey: string;
  imageUrl: string;
  definitionUrl: string;
  user: {
    username: string;
  };
  createdAt: string;
  progressBlob?: any;
}

enum PuzzleAction {
  CREATE = 'puzzles/create',
  FETCH = 'puzzles/fetch',
  FETCH_SINGLE = 'puzzles/fetch-single',
  GRANT_ACCESS = 'puzzles/grant-access',
}

const puzzlesAdapter = createEntityAdapter<Puzzle>({
  selectId: (puzzle) => puzzle.id,
});

export const fetchSingle = createAsyncThunk(
  PuzzleAction.FETCH_SINGLE,
  async (id: number) => {
    const puzzle = await client.get('/puzzles/' + id, {
      withCredentials: true,
    });
    return puzzle.data;
  }
);

export const createPuzzle = createAsyncThunk(
  PuzzleAction.CREATE,
  async (data: any) => {
    const puzzle = await client.post('/puzzles', data.data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        // data.reportProgress(progressEvent);
      },
    });
    return puzzle.data;
  }
);

export const fetchAll = createAsyncThunk(PuzzleAction.FETCH, async () => {
  const puzzles = await client.get('/puzzles');

  return puzzles.data;
});

const puzzleSlice = createSlice({
  name: 'puzzles',
  initialState: puzzlesAdapter.getInitialState({
    loading: false,
    activeId: null,
  }),
  reducers: {
    setActive(state, action) {
      state.activeId = action.payload;
    },

    clearActive(state) {
      state.activeId = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAll.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAll.fulfilled, (state, action) => {
      puzzlesAdapter.setAll(state, action.payload);
    });

    builder.addCase(fetchSingle.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(createPuzzle.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchSingle.fulfilled, (state, action) => {
      puzzlesAdapter.upsertOne(state, action.payload);

      state.activeId = action.payload.id;
      state.loading = false;
    });

    builder.addCase(createPuzzle.fulfilled, (state, action) => {
      puzzlesAdapter.addOne(state, action.payload);
    });
  },
});

export const puzzleSelectors = puzzlesAdapter.getSelectors(
  (state: RootState) => state.puzzles
);

export const { clearActive, setActive } = puzzleSlice.actions;

export default puzzleSlice.reducer;
