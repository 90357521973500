import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../axios';

interface AuthState {
  loading: boolean;
  userData: any;
}

const initialState: AuthState = {
  loading: false,
  userData: null,
};

export const login = createAsyncThunk('auth/login', async (payload: any) => {
  // Token is sent as a cookie, so it is stored automatically
  const response = await api.post('/auth/login', payload);

  return response.data;
});

export const signOut = createAsyncThunk('auth/sign-out', async (data) => {
  // The tokens are stored as cookie, so the route clears them when it is called
  // using the Set-Cookie headers
  return null;
});

export const fetchUserData = createAsyncThunk(
  'auth/fetch-user-data',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/auth');

      return {
        user: response.status === 200 ? response.data : null,
      };
    } catch (error) {
      return {
        user: null,
      };
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, (state) => {
      state.loading = false;
      state.userData = {};
    });

    builder.addCase(login.fulfilled, (state, action) => {
      const data = action.payload;

      state.userData = data;
      state.loading = false;
    });

    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      const { user } = action.payload;

      state.userData = user;
      state.loading = false;
    });

    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.loading = false;
      state.userData = null;
    });

    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(fetchUserData.pending, (state, action) => {
      state.loading = true;
    });
  },
});

export default authSlice.reducer;
