import { configureStore } from '@reduxjs/toolkit';

import authSlice from './authSlice';
// import overlaySlice from './overlaySlice';
import puzzleSlice from './puzzleSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    // overlay: overlaySlice,
    puzzles: puzzleSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
