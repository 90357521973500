export default class Side {
  constructor(side, oppositeSide) {
    this.side = side;
    this.oppositeSide = oppositeSide;
  }

  equals(s) {
    if (s instanceof Side) {
      return this.side === s.side;
    }

    return this.side === s;
  }

  toString() {
    return this.side;
  }

  opposite() {
    return this.oppositeSide;
  }
}
