import Dexie, { Table } from 'dexie';

// TODO: re-type definition to puzzle interface

export interface PuzzleDefinition {
  id: number;
  pieces: any[];
  puzzlesheet: Blob;
}

export class Db extends Dexie {
  puzzleDefinitions!: Table<PuzzleDefinition>;

  constructor() {
    super('puzzles');
    this.version(1).stores({
      puzzleDefinitions: 'id, pieces, puzzlesheet',
    });
  }
}

export const db = new Db();
