import { Fragment } from 'react';
import Routes from './router';

import './fontStyles.css';
// import Overlay from './Overlay';

const App: React.FC = () => (
  <Fragment>
    <Routes></Routes>
    {/* <Overlay></Overlay> */}
  </Fragment>
);

export default App;
