export default class Puzzlesheet {
  constructor(opts) {
    this.spritesheet = opts.spritesheet;
    this.spriteSize = opts.spriteSize;
  }

  static async createExtractor(opts) {
    return new Promise((resolve) => {
      const blob = opts.spritesheet;

      const image = new Image();

      image.addEventListener('load', () => {
        resolve(
          new Puzzlesheet({
            ...opts,
            spritesheet: image,
          })
        );
      });

      image.src = URL.createObjectURL(blob);
    });
  }

  extractSprite(r, c) {
    const canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d');

    canvas.width = this.spriteSize;
    canvas.height = this.spriteSize;

    ctx.drawImage(
      this.spritesheet,
      c * this.spriteSize,
      r * this.spriteSize,
      this.spriteSize,
      this.spriteSize,
      0,
      0,
      this.spriteSize,
      this.spriteSize
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  }
}
