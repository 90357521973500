import React from 'react';
import Layer from '../../../libs/layer';
import { Puzzle } from '../../../libs/puzzle';

interface OwnProps {
  metadata: any;
  pieces: Puzzle[];
  shift: boolean;
}

interface State {
  currentPieceId: number | null;
  metadata: number[]; // click offsets
}

export class Map extends React.Component<OwnProps, State> {
  private readonly mapRef: React.RefObject<HTMLDivElement>;
  constructor(props: OwnProps) {
    super(props);
    this.mapRef = React.createRef();

    this.state = {
      currentPieceId: null,
      metadata: [],
    };

    this.onMove = this.onMove.bind(this);
    this.onUp = this.onUp.bind(this);
  }

  getMap() {
    return this.mapRef.current;
  }

  componentDidMount() {
    this.renderPieces(this.props.pieces);

    window.addEventListener('mousemove', this.onMove);
    window.addEventListener('mouseup', this.onUp);
  }

  renderPieces(pieces: Puzzle[]) {
    const columns = this.props.metadata.numOfColumns;
    const entryLayer = new Layer(0);

    for (let i = 0; i < pieces.length; i++) {
      const piece = pieces[i];

      piece.prepareForRender();

      const node = piece.node()!;

      node.addEventListener(
        'mousedown',
        (e: MouseEvent) => {
          e.preventDefault();

          const x = (e.clientX + 0.5) | 0;
          const y = (e.clientY + 0.5) | 0;

          const n = piece.hasGroup() ? piece.group.node() : node;

          //this.lockPiece(piece.idx);

          this.setState({
            currentPieceId: piece.idx,
            metadata: [n.offsetLeft - x, n.offsetTop - y],
          });
        },
        { capture: true }
      );

      piece.setMetadata({
        totalColumns: columns,
      });

      entryLayer.add(piece);
    }

    const map = this.getMap()!;

    for (const layer of entryLayer) {
      const layerDOMElement = Layer.createLayer(layer.id);
      layerDOMElement.appendChild(layer.fragment);
      map.appendChild(layerDOMElement);
    }
  }

  onMove(e: MouseEvent) {
    const { currentPieceId, metadata } = this.state;
    const { pieces, shift } = this.props;

    if (currentPieceId === null || shift) {
      return null;
    }

    const x = e.clientX;
    const y = e.clientY;

    const piece = pieces[currentPieceId];

    const toX = x + metadata[0];
    const toY = y + metadata[1];

    return piece.move(toX, toY);
  }

  onUp(e: MouseEvent) {
    const { currentPieceId } = this.state;

    if (currentPieceId === null) {
      return null;
    }

    const { pieces } = this.props;

    const currentPiece = pieces[currentPieceId];

    const toCheck = currentPiece.toCheck();

    for (let i = 0; i < toCheck.length; i++) {
      const { side, idx } = toCheck[i];

      const checkWith = pieces[idx];

      if (
        !checkWith ||
        (checkWith.hasGroup() &&
          currentPiece.hasGroup() &&
          checkWith.group.id === currentPiece.group.id)
      ) {
        continue;
      }

      const doesIntersect = checkWith.intersectsAtSide(side, currentPiece);
      const container = document.getElementById(`layer-${currentPiece.layer}`);

      if (doesIntersect) {
        checkWith.connect(currentPiece, side, container);
      }
    }

    this.setState({
      currentPieceId: null,
    });
  }

  render() {
    return <div id="map" className="map" ref={this.mapRef}></div>;
  }
}
