import { useState } from 'react';
import Stage, { StageState } from './Stage';
import { IStage } from '..';

interface CreatePipelineProps {
  currentStage: number;
  changeStage: (stageId: number) => void;
  stages: IStage[];
}

const CreatePipeline: React.FC<CreatePipelineProps> = ({
  currentStage,
  changeStage,
  stages,
}) => {
  const calculateStageState = (stageId: number) => {
    console.log(stageId);
    if (stages[stageId].completed === true) {
      return StageState.COMPLETED;
    }

    if (stageId === currentStage) {
      return StageState.CURRENT;
    }

    return StageState.NEXT;
  };

  return (
    <div className="creation-steps">
      {/* <h1>Creation Pipeline</h1> */}

      {stages.map((stage, key) => (
        <Stage
          key={key}
          onClick={changeStage}
          state={calculateStageState(stage.id)}
          id={stage.id}
          name={stage.name}
        />
      ))}
    </div>
  );
};

export default CreatePipeline;
