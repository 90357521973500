import React from 'react';
import Button from '../../../../shared/components/Button';
import Input from '../../../../shared/components/Input';
import Spinner from '../../../../shared/components/Spinner';
import { ISpecification } from '../Editor';

interface FinalizeCreationProps {
  title: string | null;
  onChange: (name: keyof ISpecification, value: any) => void;
  create: () => void;
}

const FinalizeCreation: React.FC<FinalizeCreationProps> = ({
  create,
  title,
  onChange,
}) => {
  return (
    <div className="spec-wrapper">
      {/* <Spinner color="white" size={64}></Spinner> */}
      <Input
        name="title"
        setValue={(name, value) => onChange(name, value)}
        value={title}
        type="text"
        placeholder="Puzzle title"
        label="Title"
      ></Input>

      <Button text="Create" onClick={() => create()}></Button>
    </div>
  );
};

export default FinalizeCreation;
