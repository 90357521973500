import { Route } from 'react-router';
import { BrowserRouter, Routes as Switch, Navigate } from 'react-router-dom';
import Home from '../modules/Home';
import Login from '../modules/Login';
import Playground from '../modules/Playground';
import PrivateRoute from '../shared/components/PrivateRoute';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" element={<Navigate to="/app" replace />}></Route>
      <Route
        path="/app"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
          // <Home />
        }
      />
      <Route path="login" element={<Login />} />
      <Route
        path="/app/playground/:key"
        element={
          <PrivateRoute>
            <Playground ></Playground>
          </PrivateRoute>
        }
      />
    </Switch>
  </BrowserRouter>
);

export default Routes;
