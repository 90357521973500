export default class Layer {
  static MAX_ELEMENTS = 800;

  constructor(id) {
    this.fragment = document.createDocumentFragment();
    this.id = id;
    this.elements = [];
    this.nextLayer = null;
  }

  *[Symbol.iterator]() {
    let layer = this;

    while (layer !== null) {
      yield layer;
      layer = layer.nextLayer;
    }
  }

  static createLayer(id) {
    const layer = document.createElement('div');

    layer.setAttribute('class', 'layer');
    layer.setAttribute('id', `layer-${id}`);
    layer.style.zIndex = id;

    return layer;
  }

  createNextLayer() {
    return new Layer(this.id + 1);
  }

  hasNextLayer() {
    return !!this.nextLayer;
  }

  attemptNextLayer(p) {
    if (!this.hasNextLayer()) {
      this.nextLayer = this.createNextLayer();
    }

    return this.nextLayer.add(p);
  }

  insert(p) {
    this.elements.push(p);
    this.fragment.appendChild(p.hasGroup() ? p.group.node() : p.node());
    p.setLayer(this.id);
    return true;
  }

  add(p) {
    if (this.elements.length >= Layer.MAX_ELEMENTS) {
      return this.attemptNextLayer(p);
    }

    return this.insert(p);
  }
}
