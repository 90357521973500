import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';
import { login } from '../../app/store/authSlice';
import { useEffect } from 'react';
import { useAppDispatch } from '../../shared/hooks/useAppDispatch';

const Login: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth.userData !== null) {
      navigate('/app');
    }
  }, [auth.userData, navigate]);

  const handleButtonClick = () => {
    dispatch(
      login({
        username: 'purplebear',
        password: 'purplebear3',
      })
    );
  };

  return (
    <div>
      <button onClick={() => handleButtonClick()}>Test LOGIN</button>
    </div>
  );
};

export default Login;
