import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Navigate } from 'react-router-dom';
import { RootState } from '../../../app/store';

interface PrivateRouteProps {
  children?: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const auth = useSelector((state: RootState) => state.auth);

  if (auth.loading) {
    return <div>Loading...</div>;
  }

  if (!auth.userData) {
    return <Navigate to={'/login'}></Navigate>;
  }

  return <Fragment>{children}</Fragment>;
};

export default PrivateRoute;
