import axios from 'axios';

const client = axios.create({
  baseURL: 'https://puzzle.purplebear.io/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    const isRefresh = config.url === '/auth/refresh';

    if (!isRefresh && error.response.status === 401 && !config?.sent) {
      config.sent = true;

      const response = await client.get('/auth/refresh');

      return axios(config);
    }

    return Promise.reject(error);
  }
);

// TODO: add interceptor

export default client;
