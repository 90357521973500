import Icon, { Icons } from '../../../shared/components/Icon/Icon';

export enum Context {
  OVERVIEW = 'overview',
  COMPLETED = 'completed',
  CREATE_WIZARD = 'create_wizard',
}

interface TopMenuProps {
  onContextSwitch: (contextId: Context) => void;
  activeContextId: Context;
}

const TopMenu: React.FC<TopMenuProps> = ({
  activeContextId,
  onContextSwitch,
}) => {
  return (
    <div className={`top-menu`}>
      <div
        onClick={() => onContextSwitch(Context.OVERVIEW)}
        className={`top-menu-button ${
          activeContextId === Context.OVERVIEW ? 'active' : ''
        }`}
      >
        <div>
          <Icon icon={Icons.INFO} size={24} strokeWidth={2.5} />
          <span>Overview</span>
        </div>
      </div>
      <div
        onClick={() => onContextSwitch(Context.COMPLETED)}
        className={`top-menu-button ${
          activeContextId === Context.COMPLETED ? 'active' : ''
        }`}
      >
        <div>
          <Icon icon={Icons.CHECK_CIRCLE} size={24} strokeWidth={2.5} />
          <span>Finished</span>
        </div>
      </div>
      <div
        onClick={() => onContextSwitch(Context.CREATE_WIZARD)}
        className={`top-menu-button menu-button-add ${
          activeContextId === Context.CREATE_WIZARD ? 'active' : ''
        }`}
      >
        <div>
          <Icon icon={Icons.PLUSCIRCLE} size={24} strokeWidth={2.5} />
          <span>
            <b>Create</b>
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
