import { useNavigate } from 'react-router';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';

interface PuzzleListProps {
  puzzles: any[];
}

const PuzzleList: React.FC<PuzzleListProps> = ({ puzzles }) => {
  const navigate = useNavigate();

  const onPuzzleItemClick = (key: string) => {
    return navigate('/app/playground/' + key);
  };

  const itemList = puzzles.map((puzzle) => {
    return (
      <div className="list-item">
        <img src={puzzle.assets.imageUrl} className="list-image"></img>
        <div className="data">
          <div className="title">{puzzle.title}</div>
          <div className="pieces">
            {puzzle.numOfColumns * puzzle.numOfRows}
            <Icon icon={Icons.LAYERS} size={18} strokeWidth={2.5} />
          </div>
          <div className="creation-date">
            {new Date(puzzle.createdAt).toLocaleDateString('sl')}
          </div>
          <div className="creator">{puzzle.user.username}</div>
          <div className="action-menu">
            <span
              className="play"
              onClick={() => onPuzzleItemClick(puzzle.collaborationKey)}
            >
              <span>Play</span>
              <Icon icon={Icons.PLAY} size={24} strokeWidth={2.5} />
            </span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="puzzle-list">
      <h2>Puzzles</h2>
      {itemList}
    </div>
  );
};

export default PuzzleList;
