import React from 'react';
import Button from '../../../../shared/components/Button';
import Icon, { Icons } from '../../../../shared/components/Icon/Icon';
import Input from '../../../../shared/components/Input';
import CanvasGrid from '../CanvasGrid';

export interface ISpecification {
  rows: number | null;
  columns: number | null;
  scale: number | null;
  baseSize: number | null;
}

interface EditorProps {
  data: ISpecification;
  image: File | null;
  onChange: (name: keyof ISpecification, value: any) => void;
  onContinue: (e: any) => void;
}

const Editor: React.FC<EditorProps> = ({
  onContinue,
  image,
  onChange,
  data,
}) => {
  const totalPieces = (data.columns || 0) * (data.rows || 0);

  return (
    <div className="spec-sheet">
      <h1>Specification</h1>
      <div className="spec-data">
        <div className="input-row">
          <Input
            label="Single piece size"
            name="baseSize"
            setValue={onChange}
            value={data.baseSize}
            type="number"
            placeholder="Piece size"
          ></Input>
          {/* <Input
            label="Image scale"
            name="scale"
            setValue={onChange}
            value={data.scale}
            type="text"
            placeholder="Scale"
          ></Input> */}
          <Input
            label="Number of columns"
            name="columns"
            setValue={onChange}
            value={data.columns}
            type="number"
            placeholder="Number of columns"
          ></Input>
          <div className="x-icon">
            <Icon icon={Icons.X} size={24}></Icon>
          </div>
          <Input
            label="Number of rows"
            name="rows"
            setValue={onChange}
            value={data.rows}
            type="number"
            placeholder="Number of rows"
          ></Input>
          <div className="total-pieces">
            <span>
              <Icon size={24} icon={Icons.ALIGN_JUSTIFY}></Icon>
            </span>
            <span>{totalPieces}</span>
            <span>pieces</span>
          </div>
        </div>
        <Button
          className="ml-auto"
          onClick={(e) => onContinue(e)}
          text="Continue"
          primary
        ></Button>
      </div>

      <CanvasGrid
        baseSize={data.baseSize}
        columns={data.columns}
        image={image}
        rows={data.rows}
        scale={data.scale}
      ></CanvasGrid>
    </div>
  );
};

export default Editor;
