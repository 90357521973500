import './Input.css';

import React from 'react';
import Icon, { Icons } from '../Icon/Icon';

interface OwnProps {
  placeholder?: string;
  value: any;
  setValue: (name: any, value: any) => any;
  type: string;
  name: string;
  label: string;
}

type Props = OwnProps;

class Input extends React.Component<Props> {
  onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.type === 'number') {
      return this.props.setValue(e.target.name, parseInt(e.target.value, 10));
    }

    return this.props.setValue(e.target.name, e.target.value);
  }

  render() {
    return (
      <label className="input-label">
        {/* <span className="input-text">{this.props.label}</span> */}
        <input
          className="input"
          name={this.props.name}
          onChange={(e) => this.onChange(e)}
          value={this.props.value}
          type={this.props.type}
          placeholder={this.props.placeholder}
        />
      </label>
    );
  }
}

export default Input;
