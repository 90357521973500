import './index.css';

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../app/store';

import OverlayWrapper from '../../../shared/components/Overlay/OverlayWrapper';
import Button from '../../../shared/components/Button';
import Icon, { Icons } from '../../../shared/components/Icon/Icon';
import Input from '../../../shared/components/Input';
import OverlayBody from '../../../shared/components/Overlay/OverlayBody';
import OverlayFooter from '../../../shared/components/Overlay/OverlayFooter';
import OverlayHeader from '../../../shared/components/Overlay/OverlayHeader';
import { createPuzzle } from '../../../app/store/puzzleSlice';
import CreatePipeline from './CreatePipeline';
import ImageChooser from './ImageChooser';
import Editor, { ISpecification } from './Editor';
import StageRenderer from './StageRenderer';
import { StageState } from './CreatePipeline/Stage';
import { Divide } from 'react-feather';
import FinalizeCreation from './FinalizeCreation';
import { RoutedProps, withRouter } from '../../../shared/hocs/withRouter';

const mapDispatchToProps = {
  createPuzzle,
};

export interface IStage {
  id: number;
  name: string;
  completed: boolean;
}

const connector = connect(null, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RoutedProps;

interface State extends ISpecification {
  title: string | null;
  rows: number | null;
  columns: number | null;
  scale: number | null;
  file: File | null;
  currentStage: number;
  stages: IStage[];
  baseSize: number | null;
}

// Static
const stages: IStage[] = [
  {
    name: 'Image',
    id: 0,
    completed: false,
  },
  {
    name: 'Specifications',
    id: 1,
    completed: false,
  },
  {
    name: 'Summary',
    id: 2,
    completed: false,
  },
];

class CreateWizard extends React.Component<Props, State> {
  private fileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      columns: null,
      rows: null,
      title: '',
      file: null,
      scale: 1,
      baseSize: 90,
      currentStage: 0,
      stages: stages,
    };

    this.fileInputRef = React.createRef();
  }

  onTitleChange(v: string) {
    this.setState({
      title: v,
    });
  }

  onRowsChange(v: string) {
    this.setState({
      rows: parseInt(v),
    });
  }

  onColumnChange(v: string) {
    this.setState({
      columns: parseInt(v),
    });
  }

  onUploadFileClick() {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.files = null;
      this.fileInputRef.current.click();
    }
  }

  onImage(image: File) {
    this.setState({
      file: image,
    });

    this.moveOntoStage(this.state.currentStage + 1, true);
  }

  async onSaveClick() {
    if (
      !this.state.file ||
      !this.state.rows ||
      !this.state.columns ||
      !this.state.title ||
      !this.state.baseSize
    ) {
      return;
    }

    const puzzleData = new FormData();

    puzzleData.append('title', this.state.title);
    puzzleData.append('rows', this.state.rows.toString());
    puzzleData.append('columns', this.state.columns.toString());
    puzzleData.append('thumbnail', this.state.file);
    puzzleData.append('baseSize', this.state.baseSize.toString());

    const reportUploadPercentage = (v: number) => {};

    this.props
      .createPuzzle({
        data: puzzleData,
        reportProgress: reportUploadPercentage,
      })
      .unwrap()
      .then((data) => {
        this.props.navigate('/app/playground/' + data.collaborationKey);
      });
  }

  moveOntoStage(stageId: number, mark: boolean = false) {
    if (mark) {
      this.markCurrentStageAsCompleted();
    }

    if (stageId === 0) {
      return;
    }

    if (
      mark === true ||
      stageId === 0 ||
      this.state.stages[stageId - 1].completed === true
    ) {
      this.setState({
        currentStage: stageId,
      });
    }
  }

  markCurrentStageAsCompleted() {
    this.setState((state) => {
      return {
        ...state,
        stages: state.stages.map((stage) => {
          return {
            ...stage,
            completed: stage.id === state.currentStage ? true : stage.completed,
          };
        }),
      };
    });
  }

  onSpecification(name: keyof ISpecification, value: any) {
    this.setState({
      [name]: value,
    } as Pick<ISpecification, keyof ISpecification>);
  }

  render(): React.ReactNode {
    return (
      <div className="create-wizard">
        <StageRenderer
          stageId={this.state.currentStage}
          renderStage={(stageId) => {
            if (stageId === 0) {
              if (this.state.stages[stageId].completed) {
                return <div>Hello</div>;
              }

              return (
                <ImageChooser
                  onImage={(file) => this.onImage(file)}
                ></ImageChooser>
              );
            }

            if (stageId === 1) {
              return (
                <Editor
                  onContinue={() =>
                    this.moveOntoStage(this.state.currentStage + 1, true)
                  }
                  image={this.state.file}
                  data={{
                    columns: this.state.columns,
                    baseSize: this.state.baseSize,
                    rows: this.state.rows,
                    scale: this.state.scale,
                  }}
                  onChange={(name, value) => this.onSpecification(name, value)}
                ></Editor>
              );
            }

            if (stageId === 2) {
              return (
                <FinalizeCreation
                  create={() => this.onSaveClick()}
                  title={this.state.title}
                  onChange={(name, value) => this.onSpecification(name, value)}
                ></FinalizeCreation>
              );
            }
          }}
        ></StageRenderer>

        <CreatePipeline
          stages={this.state.stages}
          changeStage={(stageId) => this.moveOntoStage(stageId, false)}
          currentStage={this.state.currentStage}
        ></CreatePipeline>
      </div>
    );
  }
}

export default connector(withRouter(CreateWizard));
