import { Component } from 'react';

interface StageRendererProps {
  stageId: number;
  renderStage: (stageId: number) => React.ReactNode;
}

const StageRenderer: React.FC<StageRendererProps> = ({
  stageId,
  renderStage,
}) => {
  return <div className="stage-data">{renderStage(stageId)}</div>;
};

export default StageRenderer;
