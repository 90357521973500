import PuzzleColliders from './puzzle-collider';
import Side from './side';

export default class Events {
  static emit(type, detail) {
    window.dispatchEvent(new CustomEvent(type, { detail: detail }));
  }

  static on(type, callback) {
    return window.addEventListener(type, callback, false);
  }

  // TODO: add off
}

export class PuzzleGroup {
  constructor(id, position) {
    this.id = id || Date.now();
    this.puzzles = new Map();
    this.position = position || {
      x: 0,
      y: 0,
    };

    this.capsuleElement = null;

    // Notify the app of a new group
    Events.emit('group-created', this.getCurrentState());
  }

  static create(id, position) {
    return new PuzzleGroup(id, position);
  }

  getCurrentState() {
    return {
      x: this.position.x,
      y: this.position.y,
      gid: this.id,
    };
  }

  node() {
    if (!this.capsuleElement) {
      return this.createCapsuleNode();
    }

    return this.capsuleElement;
  }

  setPosition(v) {
    this.position = v;
    return this;
  }

  getPuzzle(id) {
    return this.puzzles.get(id);
  }

  hasPuzzle(id) {
    return this.puzzles.has(id);
  }

  createCapsuleNode() {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.top = `${this.position.y}px`;
    div.style.left = `${this.position.x}px`;

    for (const [_, v] of this.puzzles.entries()) {
      div.append(v.node());
    }

    this.capsuleElement = div;

    return div;
  }

  add(...v) {
    v.forEach((puzzle) => {
      this.puzzles.set(puzzle.idx, puzzle);
      puzzle.joinGroup(this);
    });
  }

  merge(v) {
    const queue = v.getNeighbourPieces();
    const excludeList = new Set([v.idx]);

    while (queue.length > 0) {
      console.log('This should be fired exactly 8 times');
      const { side, v: piece } = queue.shift();

      if (Puzzle.SIDES.LEFT.equals(side)) {
        const right = piece.getNeighbourAtSide(Puzzle.SIDES.RIGHT);

        if (right) {
          piece.adjustPosition(
            right.position.x - piece.originalSize,
            right.position.y
          );
        }
      }

      if (Puzzle.SIDES.RIGHT.equals(side)) {
        const left = piece.getNeighbourAtSide(Puzzle.SIDES.LEFT);

        if (left) {
          piece.adjustPosition(
            left.position.x + piece.originalSize,
            left.position.y
          );
        }
      }

      if (Puzzle.SIDES.TOP.equals(side)) {
        const bottom = piece.getNeighbourAtSide(Puzzle.SIDES.BOTTOM);

        if (bottom) {
          piece.adjustPosition(
            bottom.position.x,
            bottom.position.y - piece.originalSize
          );
        }
      }

      if (Puzzle.SIDES.BOTTOM.equals(side)) {
        const top = piece.getNeighbourAtSide(Puzzle.SIDES.TOP);

        if (top) {
          piece.adjustPosition(
            top.position.x,
            top.position.y + piece.originalSize
          );
        }
      }

      const toEnqueue = piece.getNeighbourPieces();

      for (let i = 0; i < toEnqueue.length; i++) {
        if (toEnqueue[i].v && excludeList.has(toEnqueue[i].v.idx)) {
          continue;
        }

        queue.push(toEnqueue[i]);
        excludeList.add(toEnqueue[i].v.idx);
      }
    }

    v.group.puzzles.forEach((p) => {
      this.puzzles.set(p.idx, p);
      p.joinGroup(this);
    });
  }

  move(x, y) {
    this.capsuleElement.style.top = `${y}px`;
    this.capsuleElement.style.left = `${x}px`;

    this.setPosition({
      x,
      y,
    });

    Events.emit('group-move', {
      gid: this.id,
      x,
      y,
    });
  }
}

export class Puzzle {
  static SIDES = {
    LEFT: new Side('left', 'right'),
    TOP: new Side('top', 'bottom'),
    BOTTOM: new Side('bottom', 'top'),
    RIGHT: new Side('right', 'left'),
  };

  static SIDES_ARR = ['left', 'top', 'bottom', 'right'];

  constructor({ sides, size, row, column, idx, actualSize, imgSrc }) {
    this.sides = sides;
    this.originalSize = size;
    this.row = row;
    this.column = column;
    this.idx = idx;
    this.actualSize = actualSize;
    this.imgSrc = imgSrc;

    this.metadata = {};

    // These numbers have no meaning

    this.position = {
      x: (Math.random() * 1800) | 0,
      y: (Math.random() * 800) | 0,
    };

    this.zoneSize = this.originalSize / 3;
    this.imageNode = null;
    this.group = null;
    this.colliders = null;

    this.renderable = false;
  }

  prepareForRender() {
    if (!this.renderable) {
      this.imageNode = this.createImageNode();
      this.colliders = PuzzleColliders.create(this.zoneSize, this.originalSize);
      this.renderable = true;
    }
  }

  static create(data) {
    return new Puzzle(data);
  }

  toCheck() {
    return [
      {
        side: Puzzle.SIDES.LEFT,
        idx: this.idx + 1,
      },
      {
        side: Puzzle.SIDES.RIGHT,
        idx: this.idx - 1,
      },
      {
        side: Puzzle.SIDES.TOP,
        idx: this.idx + this.getMetadata('totalColumns'),
      },
      {
        side: Puzzle.SIDES.BOTTOM,
        idx: this.idx - this.getMetadata('totalColumns'),
      },
    ];
  }

  getCurrentState() {
    const gid = this.hasGroup() ? this.group.id : null;

    return {
      x: this.position.x,
      y: this.position.y,
      gid,
      id: this.idx,
    };
  }

  getAbsolutePosition() {
    const { x, y } = this.imageNode.getBoundingClientRect();
    return {
      x,
      y,
    };
  }

  overlap(r1, r2) {
    return (
      r1.x < r2.x + this.zoneSize &&
      r1.x + this.zoneSize > r2.x &&
      r1.y < r2.y + this.zoneSize &&
      r1.y + this.zoneSize > r2.y
    );
  }

  intersectsAtSide(side, piece) {
    const currentPiece = this.colliders.get(side, this.getAbsolutePosition());
    const checkPiece = piece.colliders.get(
      side.opposite(),
      piece.getAbsolutePosition()
    );

    return this.overlap(currentPiece, checkPiece);
  }

  connect(v, at, container) {
    if (!this.hasGroup() && !v.hasGroup()) {
      const group = new PuzzleGroup();

      group.setPosition(this.position);
      this.adjustPosition(0, 0);

      if (Puzzle.SIDES.LEFT.equals(at)) {
        v.adjustPosition(-this.originalSize, 0);
      }

      if (Puzzle.SIDES.RIGHT.equals(at)) {
        v.adjustPosition(this.originalSize, 0);
      }

      if (Puzzle.SIDES.BOTTOM.equals(at)) {
        v.adjustPosition(0, this.originalSize);
      }

      if (Puzzle.SIDES.TOP.equals(at)) {
        v.adjustPosition(0, -this.originalSize);
      }

      container.appendChild(group.createCapsuleNode());

      return group.add(this, v);
    }

    if (this.hasGroup() && !v.hasGroup()) {
      if (Puzzle.SIDES.LEFT.equals(at)) {
        v.adjustPosition(this.position.x - this.originalSize, this.position.y);
      }

      if (Puzzle.SIDES.RIGHT.equals(at)) {
        v.adjustPosition(this.position.x + this.originalSize, this.position.y);
      }

      if (Puzzle.SIDES.BOTTOM.equals(at)) {
        v.adjustPosition(this.position.x, this.position.y + this.originalSize);
      }

      if (Puzzle.SIDES.TOP.equals(at)) {
        v.adjustPosition(this.position.x, this.position.y - this.originalSize);
      }

      return this.group.add(v);
    }

    if (!this.hasGroup() && v.hasGroup()) {
      if (Puzzle.SIDES.LEFT.equals(at)) {
        this.adjustPosition(v.position.x + this.originalSize, v.position.y);
      }

      if (Puzzle.SIDES.RIGHT.equals(at)) {
        this.adjustPosition(v.position.x - this.originalSize, v.position.y);
      }

      if (Puzzle.SIDES.BOTTOM.equals(at)) {
        this.adjustPosition(v.position.x, v.position.y - this.originalSize);
      }

      if (Puzzle.SIDES.TOP.equals(at)) {
        this.adjustPosition(v.position.x, v.position.y + this.originalSize);
      }

      return v.group.add(this);
    }

    if (this.hasGroup() && v.hasGroup()) {
      if (Puzzle.SIDES.LEFT.equals(at)) {
        v.adjustPosition(this.position.x - this.originalSize, this.position.y);
      }

      if (Puzzle.SIDES.RIGHT.equals(at)) {
        v.adjustPosition(this.position.x + this.originalSize, this.position.y);
      }

      if (Puzzle.SIDES.TOP.equals(at)) {
        v.adjustPosition(this.position.x, this.position.y - this.originalSize);
      }

      if (Puzzle.SIDES.BOTTOM.equals(at)) {
        v.adjustPosition(this.position.x, this.position.y + this.originalSize);
      }

      return this.group.merge(v);
    }
  }

  hasGroup() {
    return this.group !== null;
  }

  getMetadata(key) {
    return this.metadata[key];
  }

  getNeighbourAtSide(side) {
    if (Puzzle.SIDES.LEFT.equals(side) && this.sides[3] !== 'e') {
      return this.group.getPuzzle(this.idx - 1);
    }

    if (Puzzle.SIDES.RIGHT.equals(side) && this.sides[1] !== 'e') {
      return this.group.getPuzzle(this.idx + 1);
    }

    if (Puzzle.SIDES.TOP.equals(side) && this.sides[0] !== 'e') {
      return this.group.getPuzzle(this.idx - this.metadata.totalColumns);
    }

    if (Puzzle.SIDES.BOTTOM.equals(side) && this.sides[2] !== 'e') {
      return this.group.getPuzzle(this.idx + this.metadata.totalColumns);
    }
  }

  getNeighbourPieces() {
    const neighbours = [];

    Puzzle.SIDES_ARR.forEach((s) => {
      const neighbour = this.getNeighbourAtSide(s);

      if (!!neighbour) {
        neighbours.push({
          side: s,
          v: neighbour,
        });
      }
    });

    return neighbours;
  }

  joinGroup(g) {
    this.group = g;

    if (this.group.capsuleElement) {
      this.group.capsuleElement.appendChild(this.node());
    }

    Events.emit('puzzle-join-group', {
      id: this.idx,
      gid: g.id,
    });
  }

  adjustPosition(x, y) {
    const node = this.node();

    node.style.top = `${y}px`;
    node.style.left = `${x}px`;

    this.setPosition({ x, y });

    return Events.emit('puzzle-move', { id: this.idx, x, y });
  }

  setMetadata(v) {
    this.metadata = v;
  }

  setLayer(layer) {
    this.layer = layer;
  }

  blobToUrl() {
    return URL.createObjectURL(this.imgSrc);
  }

  createImageNode() {
    const image = new Image();
    image.src = this.blobToUrl();
    image.width = this.actualSize;
    image.height = this.actualSize;

    image.style.position = 'absolute';
    // image.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
    image.style.top = `${this.position.y}px`;
    image.style.left = `${this.position.x}px`;

    return image;
  }

  move(x, y) {
    if (this.hasGroup()) {
      return this.group.move(x, y);
    }

    this.adjustPosition(x, y);
  }

  setPosition(v) {
    this.position = v;
  }

  node() {
    if (!this.renderable) {
      this.prepareForRender();
    }

    return this.imageNode;
  }
}
