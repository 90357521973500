import React from 'react';

interface State {
  shiftPressed: boolean;
  leftClicked: boolean;
  moveMetadata: number[];
}

interface Props {
  children: React.ReactNode;
  shift: boolean;
}

export default class Viewport extends React.Component<Props, State> {
  private viewportRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      shiftPressed: false,
      leftClicked: false,
      moveMetadata: [],
    };

    this.viewportRef = React.createRef();
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.shift === false && this.props.shift === true) {
      this.createMoveListeners();
    }

    if (prevProps.shift === true && this.props.shift === false) {
      this.removeMoveListeners();
    }
  }

  createMoveListeners() {
    const viewport = this.viewportRef.current!;

    viewport.addEventListener('mousedown', this.onMouseDown, {
      capture: true,
      passive: true,
    });

    viewport.addEventListener('mousemove', this.onMouseMove, {
      capture: true,
      passive: true,
    });
    viewport.addEventListener('mouseup', this.onMouseUp);
  }

  removeMoveListeners() {
    const viewport = this.viewportRef.current!;

    viewport.removeEventListener('mousedown', this.onMouseDown, {
      capture: true,
    });
    viewport.removeEventListener('mousemove', this.onMouseMove, {
      capture: true,
    });
    viewport.removeEventListener('mouseup', this.onMouseUp);
  }

  onMouseMove(e: MouseEvent) {
    // e.stopPropagation();
    e.stopImmediatePropagation();

    if (this.state.leftClicked === false) {
      return;
    }

    const div = this.viewportRef.current!.children[0] as HTMLDivElement;

    const newX = e.clientX - this.state.moveMetadata[0];
    const newY = e.clientY - this.state.moveMetadata[1];
    const calcX = this.state.moveMetadata[2] + newX;
    const calcY = this.state.moveMetadata[3] + newY;

    if (calcX < 1600 && calcX > -1600) div.style.left = calcX + 'px';

    if (calcY < 1600 && calcY > -1600) div.style.top = calcY + 'px';
  }

  onMouseDown(e: MouseEvent) {
    const div = this.viewportRef.current!.children[0] as HTMLDivElement;

    const startX = e.clientX;
    const startY = e.clientY;
    const offsetX = div.offsetLeft;
    const offsetY = div.offsetTop;

    this.setState({
      leftClicked: true,
      moveMetadata: [startX, startY, offsetX, offsetY],
    });
  }

  onMouseUp(e: MouseEvent) {
    if (this.state.leftClicked) {
      this.setState({
        leftClicked: false,
        moveMetadata: [],
      });
    }
  }

  render() {
    return (
      <div className="viewport" id="viewport" ref={this.viewportRef}>
        {this.props.children}
      </div>
    );
  }
}
