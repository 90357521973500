import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { fetchAll, puzzleSelectors } from '../../app/store/puzzleSlice';
import { useAppDispatch } from '../../shared/hooks/useAppDispatch';
import CreateWizard from './CreateWizard';
import './index.css';
import PuzzleList from './PuzzleList';
import TopMenu, { Context } from './TopMenu';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useSelector((state: RootState) => state.auth.userData);
  const puzzles = useSelector(puzzleSelectors.selectAll);
  const [context, setContext] = useState(Context.OVERVIEW);

  useEffect(() => {
    dispatch(fetchAll());
  }, []);

  const renderContext = (contextId: Context) => {
    if (contextId === Context.OVERVIEW || contextId === Context.COMPLETED) {
      return <PuzzleList puzzles={puzzles} />;
    }

    if (contextId === Context.CREATE_WIZARD) {
      return <CreateWizard />;
    }
  };

  return (
    <main>
      <TopMenu activeContextId={context} onContextSwitch={setContext}></TopMenu>
      {renderContext(context)}
    </main>
  );
};

export default Home;
