import './index.css';

import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../app/store';
import {
  clearActive,
  fetchSingle,
  puzzleSelectors,
} from '../../app/store/puzzleSlice';
import React from 'react';
import client from '../../app/axios';
import { RoutedProps, withRouter } from '../../shared/hocs/withRouter';
import { db } from '../../shared/db';
import Events, { Puzzle } from '../../libs/puzzle';
import Viewport from './components/Viewport';
import io, { Socket } from 'socket.io-client';
import { Map } from './components/Map';
import { PuzzleAdapter } from './PuzzleAdapter';
import { PuzzleRebuilder } from './PuzzleRebuilder';

const mapStateToProps = (state: RootState) => ({
  puzzles: state.puzzles,
  activePuzzle: state.puzzles.activeId
    ? puzzleSelectors.selectById(state, state.puzzles.activeId)
    : null,
});

const mapDispatchToProps = {
  fetchSingle,
  clearActive,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & RoutedProps;

interface State {
  collaborators: any[];
  puzzlePieces: Puzzle[];
  currentPiece: {
    id: number | null;
  };

  metadata: {
    [key: string]: any;
  };
  shiftPressed: boolean;
  isConnected: boolean;
}

let socket: Socket;

// All the socket events move to map?

// Not all because we still need to recieve updates
// for collaborators
// which wont be handled in map component
// but in the playground component
//

class Playground extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      collaborators: [],
      currentPiece: {
        id: null,
      },
      metadata: {},
      puzzlePieces: [],
      isConnected: false,
      shiftPressed: false,
    };
  }

  async componentDidMount() {
    const { key } = this.props.params;

    if (!key) {
      return this.props.navigate('/app');
    }

    try {
      const { data: status } = await client.post(`/puzzles/${key}/join`);

      if (!status.granted) {
        return this.props.navigate('/app');
      }

      this.props.fetchSingle(status.id);

      // Initialize a socket connection

      socket = io('wss://puzzle.purplebear.io', {
        withCredentials: true,
        transports: ['websocket'],
        path: '/ws',
        autoConnect: false,
        query: {
          key,
        },
      });

      socket.on('connect', () => {
        console.log('Connected');
        this.setState({
          isConnected: true,
        });
      });

      return this.createEventListeners();
    } catch (error) {
      return this.props.navigate('/app');
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      this.props.puzzles.activeId !== null &&
      this.state.puzzlePieces.length === 0 &&
      this.state.isConnected === false
    ) {
      this.rebuildPuzzle();
    }

    if (prevState.isConnected === false && this.state.isConnected === true) {
      this.listen();
    }
  }

  componentWillUnmount(): void {
    this.props.clearActive();
    this.removeEventListeners();

    if (socket) {
      socket.off('connect');
      socket.disconnect();
    }
  }

  lockPiece(id: number) {
    console.log('[DEBUG]: Locking piece with id: ' + id);
    socket.emit('lock', {
      pieceId: id,
    });
  }

  unlockPiece() {
    console.log('[DEBUG]: Unlocking piece current locked piece');
    socket.emit('lock', {
      pieceId: null,
    });
  }

  listen() {
    Events.on('puzzle-move', ({ detail }: CustomEvent) => {
      socket.emit('puzzle-move', detail);
    });
    Events.on('puzzle-join-group', ({ detail }: CustomEvent) => {
      socket.emit('puzzle-join-group', detail);
    });

    Events.on('group-move', ({ detail }: CustomEvent) => {
      socket.emit('group-move', detail);
    });
    Events.on('group-created', ({ detail }: CustomEvent) => {
      socket.emit('group-created', detail);
    });
  }

  async rebuildPuzzle() {
    console.log('Rebuilding puzzle');
    const { id, progressBlob } = this.props.activePuzzle!;

    const { pieces } = await this.getPuzzleDefinition(id);

    this.setState({
      puzzlePieces: PuzzleRebuilder.rebuild(pieces, progressBlob),
    });

    socket.connect();
  }

  async getPuzzleDefinition(id: number) {
    const hasDefinition = await db.puzzleDefinitions.get(id);

    if (!hasDefinition) {
      const [definition, puzzlesheet] = await Promise.all([
        PuzzleAdapter.fetchDefinition(id),
        PuzzleAdapter.fetchSheet(id),
      ]);

      const puzzles = await PuzzleRebuilder.fromDefinition(
        definition,
        puzzlesheet
      );

      await db.puzzleDefinitions.add({
        id,
        pieces: puzzles,
        puzzlesheet,
      });
    }

    const def = await db.puzzleDefinitions.get(id);
    return def!;
  }

  onKeyPressed = (e: KeyboardEvent) => {
    if (e.key !== 'Shift' || this.state.shiftPressed) {
      return;
    }

    this.setState({
      shiftPressed: true,
    });
  };

  onKeyReleased = (e: KeyboardEvent) => {
    if (e.key !== 'Shift' || !this.state.shiftPressed) {
      return;
    }

    this.setState({
      shiftPressed: false,
    });
  };

  createEventListeners() {
    window.addEventListener('keydown', this.onKeyPressed);
    window.addEventListener('keyup', this.onKeyReleased);
  }

  removeEventListeners() {
    window.removeEventListener('keydown', this.onKeyPressed);
    window.removeEventListener('keyup', this.onKeyReleased);
  }

  render(): React.ReactNode {
    if (!this.props.activePuzzle || this.state.puzzlePieces.length === 0) {
      return null;
    }

    return (
      <Viewport shift={this.state.shiftPressed}>
        <Map
          shift={this.state.shiftPressed}
          metadata={this.props.activePuzzle}
          pieces={this.state.puzzlePieces}
        ></Map>
      </Viewport>
    );
  }
}

export default connector(withRouter(Playground));
