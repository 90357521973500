import * as icons from 'react-feather';
import React from 'react';

export enum Icons {
  LINK = 'Link',
  ALIGN_JUSTIFY = 'AlignJustify',
  IMAGE = 'Image',
  PLAY = 'Play',
  FOLDER = 'Folder',
  SEARCH = 'Search',
  CLOUDOFF = 'CloudOff',
  MINUS = 'Minus',
  FILE_TEXT = 'FileText',
  EDIT2 = 'Edit2',
  LAYERS = 'Layers',
  EDIT3 = 'Edit3',
  EDIT = 'Edit',
  CHEVRON_UP = 'ChevronUp',
  KEY = 'Key',
  HASH = 'Hash',
  USER = 'User',
  CHECK_CIRCLE = 'CheckCircle',
  LOCK = 'Lock',
  EXTERNAL_LINK = 'ExternalLink',
  FILTER = 'Filter',
  GLOBE = 'Globe',
  CORNER_DOWN_LEFT = 'CornerDownLeft',
  ACTIVITY = 'Activity',
  SHARE2 = 'Share2',
  MOVE = 'Move',
  TRASH2 = 'Trash2',
  DOWNLOAD = 'Download',
  SMARTPHONE = 'Smartphone',
  TABLET = 'Tablet',
  MONITOR = 'Monitor',
  HARD_DRIVE = 'HardDrive',
  PLUSCIRCLE = 'PlusCircle',
  STAR = 'Star',
  CLIPBOARD = 'Clipboard',
  UPLOAD = 'UploadCloud',
  INFO = 'Info',
  TAG = 'Tag',
  CIRCLE = 'Circle',
  REFRESH_CW = 'RefreshCcw',
  TOGGLE_LEFT = 'ToggleLeft',
  TOGGLE_RIGHT = 'ToggleRight',
  FOLDER_PLUS = 'FolderPlus',
  FILE_PLUS = 'FilePlus',
  CHEVRON_LEFT = 'ChevronLeft',
  CHEVRON_RIGHT = 'ChevronRight',
  CHEVRON_DOWN = 'ChevronDown',
  CHEVRONS_RIGHT = 'ChevronsRight',
  PLUS = 'Plus',
  CPU = 'Cpu',
  BRIEFCASE = 'Briefcase',
  PAPERCLIP = 'Paperclip',
  LIST = 'List',
  CALENDAR = 'Calendar',
  USERS = 'Users',
  TYPE = 'Type',
  CHECK = 'Check',
  X = 'X',
}

interface OwnProps {
  icon: Icons;
  size: number;
  strokeWidth?: number;
}

type Props = OwnProps;

class Icon extends React.Component<Props> {
  render() {
    const IconComponent = icons[this.props.icon];
    const strokeWidth = this.props.strokeWidth || 2;
    return <IconComponent strokeWidth={strokeWidth} size={this.props.size} />;
  }
}

export default Icon;
